.wrap {
	display: flex;
	justify-content: space-between;
	gap: var(--s24);

	.main_block {
		display: flex;
		flex-direction: column;
		width: 80%;
		gap: var(--s24);
	}
	.widgets_block {
		display: flex;
		flex-direction: column;
		gap: var(--s16);
		width: var(--s300);
		position: sticky;
		top: 0;
		height: max-content;
	}
}
